<template>
  <div class="form">
    <section class="form-section">
      <p class="form-headline">ログイン情報</p>
      <FormRow :required="true">
        <template v-slot:label>ユーザー名</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="form-group">
              <div class="form-group-item">
                <input
                  class="form-control"
                  :class="{ 'is-error': errors.nameSei }"
                  type="text"
                  v-maxlength
                  maxlength="20"
                  v-space
                  name="nameSei"
                  v-model="formData.nameSei"
                  placeholder="姓"
                />
              </div>
              <div class="form-group-item">
                <input
                  class="form-control"
                  :class="{ 'is-error': errors.nameMei }"
                  type="text"
                  v-maxlength
                  v-space
                  maxlength="20"
                  name="nameMei"
                  v-model="formData.nameMei"
                  placeholder="名"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>メールアドレス / ID</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-6">
                <input
                  class="form-control"
                  :class="{ 'is-error': errors.email }"
                  type="text"
                  name="email"
                  v-trim
                  v-maxlength
                  maxlength="50"
                  v-email
                  v-model="formData.email" />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>初回パスワード</template>
        <template v-slot:labelNote><span class="text-note">（8文字以上で半角英数字および記号（/ \ ¥ を除く）を全て組み合わせ）</span></template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-6">
                <div class="form-password">
                  <input
                    class="form-control form-password-input"
                    :class="{ 'is-error': errors.password }"
                    :type="pwdVisible ? 'text' : 'password'"
                    v-trim
                    name="password"
                    v-model="formData.password"
                  />
                  <label class="form-check form-password-toggle">
                    <input class="form-check-input" type="checkbox" value="" v-model="pwdVisible" />
                    <i class="aikon form-password-icon"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>事務局/企業情報</template>
        <template v-slot:content v-if="!isClientChild">
          <div class="form-content-row">
            <ul class="listGroup">
              <li class="listGroup-item">
                <label class="form-radio">
                  <input
                    class="form-radio-input"
                    type="radio"
                    value="selected"
                    name="typeSelectCompany"
                    v-model="companyInputType"
                  />
                  <span class="form-radio-label">登録済みの事務局/企業から選択する</span>
                </label>
                <transition name="fade">
                  <div v-if="companyInputType === 'selected'" class="form-select">
                    <SearchSelect
                      :isError="errors.companyId"
                      :options="allCompanies"
                      :multiple="false"
                      :closeOnSelect="true"
                      @change-selection="handleSelectedCompanyId"
                    />
                  </div>
                </transition>
              </li>
              <li class="listGroup-item">
                <label class="form-radio">
                  <input
                    class="form-radio-input"
                    type="radio"
                    value="created"
                    name="typeSelectCompany"
                    v-model="companyInputType"
                  />
                  <span class="form-radio-label">事務局/企業情報を入力する</span>
                </label>
              </li>
            </ul>
          </div>
        </template>
        <template v-else v-slot:content>
          <div class="form-content-row">
            <div class="form-group">
              <div class="form-group-item">{{ currentParentCompany.name }} ＞ {{ currentChildCompany.name }}</div>
            </div>
          </div>
        </template>
      </FormRow>
      <UserMailSetting v-if="companyInputType === 'selected'" :formData="formData" @updateData="updateData"/>
    </section>
    <transition name="fade">
      <section v-if="companyInputType === 'created'" class="form-section">
        <p class="form-headline">事務局/企業情報</p>
        <FormRow>
          <template v-slot:label>親となる事務局/企業を選択</template>
          <template v-slot:content>
            <div class="form-content-row form-select">
              <div class="form-group" v-if="currentParentCompany">
                <div class="form-group-item">{{ currentParentCompany.name }}</div>
              </div>
              <select v-else class="form-control form-select-input" v-model="formData.company.parentId" :class="{ 'is-error': errors.parentId }">
                <option value="">選択してください</option>
                <option v-for="company in companies" :key="company.id" :value="company.id">
                  {{ company.name }}
                </option>
              </select>
            </div>
          </template>
        </FormRow>
        <FormRow :required="true">
          <template v-slot:label>事務局/企業名</template>
          <template v-slot:labelNote>（20文字以内）</template>
          <template v-slot:content>
            <div class="form-content-row">
              <input
                class="form-control"
                :class="{ 'is-error': errors.company.name }"
                type="text"
                name="companyName"
                v-trim
                v-maxlength
                maxlength="20"
                v-model="formData.company.name"
              />
            </div>
          </template>
        </FormRow>
        <FormRow :required="true">
          <template v-slot:label>郵便番号</template>
          <template v-slot:content>
            <div class="form-content-row">
              <div class="row">
                <div class="col-3">
                  <input
                    class="form-control"
                    :class="{ 'is-error': errors.company.zipcode }"
                    type="tel"
                    name="zipcode"
                    v-number
                    v-model="formData.company.zipcode"
                    maxlength="7"
                    v-maxlength
                    @input="getAddress"
                  />
                </div>
              </div>
            </div>
          </template>
        </FormRow>
        <FormRow :required="true">
          <template v-slot:label>都道府県</template>
          <template v-slot:content>
            <div class="form-content-row">
              <div class="row">
                <div class="col-6">
                  <div class="form-select">
                    <select
                      class="form-control form-select-input"
                      v-model="formData.company.prefectureId"
                      :class="{ 'is-error': errors.company.prefectureId }"
                    >
                      <option value="">選択してください</option>
                      <option
                        v-for="prefectureId in prefectures"
                        :value="prefectureId.id"
                        :key="prefectureId.id"
                      >
                        {{ prefectureId.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </FormRow>
        <FormRow :required="true">
          <template v-slot:label>住所</template>
          <template v-slot:content>
            <div class="form-content-row">
              <div class="form-select">
                <select
                  class="form-control form-select-input"
                  v-model="formData.company.municipalityId"
                  :class="{ 'is-error': errors.company.municipalityId }"
                >
                  <option value="" selected>市区群町村</option>
                  <option
                    v-for="municipality in municipalities"
                    :value="municipality.id"
                    :key="municipality.id"
                  >
                    {{ municipality.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-content-row">
              <input
                class="form-control"
                :class="{ 'is-error': errors.company.streetAddress }"
                type="text"
                name="streetAddress"
                v-model="formData.company.streetAddress"
                v-trim
                v-maxlength
                maxlength="40"
                placeholder="丁番地"
              />
            </div>
            <div class="form-content-row">
              <input
                class="form-control"
                :class="{ 'is-error': errors.company.buildingName }"
                type="text"
                name="buildingName"
                v-model="formData.company.buildingName"
                v-trim
                v-maxlength
                maxlength="40"
                placeholder="建物名・号室"
              />
            </div>
          </template>
        </FormRow>
        <FormRow :required="true">
          <template v-slot:label>電話番号</template>
          <template v-slot:content>
            <div class="form-content-row">
              <div class="row">
                <div class="col-3">
                  <input
                    class="form-control"
                    :class="{ 'is-error': errors.company.telephone }"
                    type="tel"
                    name="telephone"
                    v-number
                    v-maxlength
                    maxlength="11"
                    v-model="formData.company.telephone"
                  />
                </div>
              </div>
            </div>
          </template>
        </FormRow>
      </section>
    </transition>
    <section class="form-section">
      <UserMailSetting v-if="companyInputType === 'created'" :formData="formData" @updateData="updateData"/>
    </section>
    <section class="form-section">
      <p class="form-headline">設定条件</p>
      <FormRow v-for="(userPrivilege, index) in userPrivilegesData" :key="index" :class="{ 'd-none': !userPrivilege.isShown }">
        <template v-slot:label>
          <span class="mw-190" :class="{ 'pd-20': userPrivilege.isChild }">{{ userPrivilege.name }}</span>
        </template>
        <template v-slot:toggle>
          <label class="form-toggle">
            <input
              class="form-toggle-input"
              :class="{ 'is-error': !!errors.role && !!errors.role[index] }"
              :disabled="$permission.isOffice() && !userChildRoles.includes(userPrivilege.code)"
              type="checkbox"
              :value="userPrivilege.code"
              v-model="formData.role"
              @change="handleUserPrivilege"
            />
            <span class="form-toggle-set">
              <span class="form-toggle-bg"></span>
              <span class="form-toggle-circle"></span>
            </span>
          </label>
        </template>
      </FormRow>
    </section>
  </div>
</template>

<script>
import clientUser from '@/mixins/module/clientUser';

export default {
  name: 'SettingUserRegisterClient',
  data: function() {
    return {
    }
  },
  mixins: [clientUser],
};
</script>

<style scoped>
.mw-190 {
  min-width: 190px;
}
</style>
